<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="名称">
          <el-input style="width: 200px" clearable v-model.trim="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 200px" v-model="searchData.status" placeholder="请选择" size="mini">
            <el-option label="下架" :value="10"> </el-option>
            <el-option label="上架" :value="20"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="name" label="名称" min-width="120"> </el-table-column>
      <el-table-column prop="type" label="类型" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.type == 1" type="primary" size="small">满减券</el-tag>
          <el-tag v-if="row.type == 2" type="success" size="small">折扣券</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="内容" min-width="150">
        <template slot-scope="{ row }">
          <div v-if="row.type == 1" type="primary" size="small">满{{ row.full_money }}减{{ row.reduce_money }}</div>
          <div v-if="row.type == 2" type="success" size="small">{{ row.discount }}折</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 10" type="danger" size="small">{{ row.status_dsc }}</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="small">{{ row.status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="有效期" min-width="230">
        <template slot-scope="{ row }">
          <div v-if="row.valid_type == 1">
            <div>开始时间：{{ row.start_time }}</div>
            <div>结束时间：{{ row.end_time }}</div>
          </div>
          <div v-if="row.valid_type == 2">有效天数：{{ row.valid_day }}天</div>
        </template>
      </el-table-column>
      <el-table-column prop="use_describe" label="描述" min-width="120"> </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="90"> </el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="160"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="">
      <el-form :model="formData" ref="form" label-width="100px" size="mini">
        <el-form-item label="名称：" prop="name" :rules="rules">
          <el-input style="width: 250px" clearable v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="type" :rules="rules">
          <el-radio-group v-model="formData.type">
            <el-radio :label="1">满减券</el-radio>
            <el-radio :label="2">折扣券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="formData.type == 1 ? '满减：' : '折扣：'" :rules="rules">
          <div class="box" v-if="formData.type == 1">
            <span>满</span>
            <el-input-number
              style="width: 120px; margin: 0 10px"
              v-model="formData.full_money"
              :precision="2"
              :step="0.01"
              :min="null"
              step-strictly
            ></el-input-number>
            <span>减</span>
            <el-input-number
              style="width: 120px; margin: 0 10px"
              v-model="formData.reduce_money"
              :precision="2"
              :step="0.01"
              :min="null"
              step-strictly
            ></el-input-number>
          </div>
          <div class="box" v-if="formData.type == 2">
            <el-input-number
              style="width: 120px; margin: 0 10px"
              v-model="formData.discount"
              :precision="1"
              :step="0.1"
              :min="0.1"
              :max="9.9"
              step-strictly
            ></el-input-number>
            <span>折</span>
          </div>
        </el-form-item>
        <el-form-item label="到期类型：" :rules="rules">
          <el-radio-group v-model="formData.valid_type">
            <el-radio :label="1">固定日期</el-radio>
            <el-radio :label="2">有效天数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="到期时间：" :rules="rules">
          <div v-if="formData.valid_type === 1">
            <el-date-picker
              style="width: 180px"
              v-model="formData.start_time"
              size="mini"
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <span style="margin: 0 10px">至</span>
            <el-date-picker
              style="width: 180px"
              v-model="formData.end_time"
              size="mini"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
          <div v-if="formData.valid_type === 2">
            <el-input-number
              style="width: 120px; margin: 0 10px"
              v-model="formData.valid_day"
              :precision="0"
              :step="1"
              :min="1"
              step-strictly
            ></el-input-number>
            <span>天</span>
          </div>
        </el-form-item>
        <el-form-item label="描述：" :rules="rules">
          <el-input style="width: 250px" type="textarea" clearable v-model="formData.use_describe"></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="status" :rules="rules">
          <el-radio-group v-model="formData.status">
            <el-radio :label="10">下架</el-radio>
            <el-radio :label="20">上架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input-number style="width: 250px" v-model="formData.sort" :step="1" :min="0" step-strictly></el-input-number>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="show_update = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, getDetailAPI, addAPI, editAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        name: '',
        status: ''
      },
      total: 0,
      formData: {
        type: 1,
        name: '',
        sort: 0,
        use_describe: '',
        status: 20,
        valid_type: 1,
        start_time: '',
        end_time: '',
        valid_day: 1,
        full_money: '',
        reduce_money: '',
        discount: ''
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.show_update = false
              this.getList()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.show_update = false
              this.getList()
            })
          }
        }
      })
    },
    //  add
    handleAdd() {
      this.formData = {
        type: 1,
        name: '',
        sort: 0,
        use_describe: '',
        status: 20,
        valid_type: 1,
        start_time: '',
        end_time: '',
        valid_day: 1,
        full_money: '',
        reduce_money: '',
        discount: ''
      }
      this.show_update = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
